<template>
  <div class="box-login">
    <!-- {{codeImgurl}} -->
    <van-nav-bar
      title="欢迎登录"
      left-text="返回"
      left-arrow
      fixed
      placeholder
      @click-left="$router.push('/')"
    >
    </van-nav-bar>
    <div class="form-title">明厨亮灶</div>
    <!-- <div class="hu_home">
      <van-icon name="arrow-left" color="#2faaea" size="1.5rem"/>
      <van-icon name="shop" color="#2faaea" size="1.5rem"/>
    </div> -->
    <div class="login-form">
      <van-form @submit="onSubmit" style="margin-top:1.5rem;">
        <van-cell-group>
          <van-field v-model="username" name="username" label="用户名" placeholder="请输入用户名" :rules="[{ required: true, message: '请填写用户名' }]" />
          <van-field v-model="password" name="password" type="password"  label="密码" placeholder="请输入密码" :rules="[{ required: true, message: '请填写密码' }]" />
          <van-field name="login_type" label="角色">
            <template #input>
              <van-radio-group v-model="login_type" direction="horizontal">
                <van-radio name="0">监管</van-radio>
                <van-radio name="1">企业</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field v-model="sms" name="verifycode" label="验证码" placeholder="请输入验证码" :rules="[{ required: true, message: '请填写验证码' }]">
            <template #button>
              <img class="code-imgs" v-bind:src="codeImgurl" @click="imgs_click_codes"/>
            </template>
          </van-field>
        </van-cell-group>
        <div style="margin: 16px;margin-top:2rem;">
          <van-button round block type="primary" native-type="submit">
            登录
          </van-button>
        </div>
      </van-form>
      <!-- <van-divider class="hu_login">选择登录端</van-divider>
      <div class="hu_login_end">
        <van-button round size="mini" color="#2faaea">监管</van-button>
        <van-button round size="mini" color="#2faaea">消费</van-button>
        <van-button round size="mini" color="#2faaea">管理</van-button>
      </div> -->
    </div>

  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { get_url_code,login_do,check_com_login_do,getlocal } from "@/api/J_Sign";
import { Toast } from 'vant'
import { useRouter } from 'vue-router'
export default {
  setup() {
    const username = ref("");
    const password = ref("");
    const login_type=ref("");
    const sms = ref("");
    let routers = useRouter()
    let codeImgurl = ref("");

    let getLogoinKeys = () =>{
      getlocal().then(res=>{
        localStorage.setItem('localinfo',res?.data?.mydata)
      })
    }

    let that = this;
    // 登录
    const onSubmit = (values) => {
      console.log('sssss',values)
        let localinfo = localStorage.getItem('localinfo') //获取登录的密钥
        console.log('本地存储',localinfo)
        login_do({...values,local_guid:localinfo}).then(res=>{
            console.log(values)
            if(res.data.statusCode == '200'){
                 Toast.success('登录成功');
                 localStorage.setItem('dptoken',res.data.mydata)
                 routers.push('/personalInfo') 
            }else{
                Toast.fail(res.data.message);
            }

        })
    };
    
    // 检测登录
    let getLoginslogin = () =>{
      check_com_login_do().then(res=>{
        if(res.data.statusCode == '200'){
          routers.push('/personalInfo')
        }
      })
    }


    onMounted(() => {
      getLogoinKeys()
      getLoginslogin()
      getCode();
    });

    // 请求验证码
    let getCode = () => {
      let a = get_url_code();
      a = a + "?r=" + Math.floor(Math.random() * 999 + 1);
      codeImgurl.value = a;
    };

    // 点击图片
    let imgs_click_codes = () =>{
        getCode()
    }
    return {
      username,
      password,
      login_type,
      sms,
      codeImgurl,
      onSubmit,
      imgs_click_codes,
    };
  },
};
</script>

<style scoped lang="less">
//头部样式
/deep/ .van-nav-bar__placeholder{
  z-index: 99999;
}
// /deep/ .van-nav-bar--fixed{
//   position: fixed;
//   left: 50%;
//   top: 0;
//   transform: translateX(-50%);
//   width: 90%;
//   border-radius: 0.4rem;
// }
/deep/ .van-nav-bar__title{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
/deep/ .van-icon-arrow-left{
  color: #323233;
}
/deep/ .van-nav-bar__text{
  color: #323233;
}
/deep/ .van-icon-star-o{
  color: #323233;
}
.box-login {
  width: 100%;
  height: 100vh;
  background: url(~@/assets/login/loginbj1.jpg) no-repeat;
  background-size: 105% 100%;
}
.form-title {
  width: 10.9rem;
  height: 3rem;
  background: red;
  background: url(~@/assets/login/douctitle.png) no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 125px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  text-align: center;
  line-height: 3rem;
  color: white;
  font-size: 17.5px;
  font-weight: bold;
  letter-spacing: 0.06rem;
}
.hu_home{
  width: 2.5rem;
  height: 2.5rem;
  background: #ffffff;
  border-radius: 50%;
  text-align: center;
  line-height: 3rem;
  position: fixed;
  top: 1.5%;
  left: 4%;
  z-index: 999;
}
.login-form {
  width: 90%;
  //   transform: translateY(12rem);
  margin: 0 auto;
  border-radius: 0.4rem;
  box-sizing: border-box;
  padding: 0.8rem 0.8rem;
  padding-top: 2.1rem;
  background: white;
  box-shadow: 1px 1px 5px 0px rgba(126, 126, 126, 0.75);
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
  .hu_login{
    margin-top: 6rem;
  }
  .hu_login_end{
    // width: 100%;
    padding: 0 1rem;
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    /deep/ .van-button--mini{
      height: 2.5rem;
      width: 2.5rem;
    }
  }
}

.code-imgs {
  width: 80px;
  height: 32px;
}
</style>